import { useCallback } from "react";
import { Button, ButtonProps, useRecordContext } from "react-admin";
// import AnonymizeIcon from "@material-ui/icons/VisibilityOff";
import AnonymizeIcon from "@material-ui/icons/VisibilityOff";
import dataProvider from "../../dataProvider";

type myProps = ButtonProps;

const AnonymizeButton = ({ ...props }: myProps) => {
  const record = useRecordContext(props);
  
  const handleConfirm = useCallback(
    async (event) => {
      console.log(
        "IN anonymize button handleConfirm",
        record.email,
        record.id,
        
      );
      await dataProvider.anonymizeUser({ id: record.id});
      
    },
    [record.email, record.id]
  );

  return record ? (
    <>
      <Button
        onClick={handleConfirm}
        {...props}
        style={{ color: "red" }}
      >
        <AnonymizeIcon />
        
      </Button>
      {/* <GetDateModal
        show={open}
        handleConfirm={handleConfirm}
        handleClose={handleClose}
      ></GetDateModal> */}
    </>
  ) : (
    <></>
  );
};

export default AnonymizeButton;
