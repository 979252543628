import {
  AutocompleteArrayInput,
  AutocompleteInput,
  Edit,
  EditProps,
  FileField,
  FileInput,
  FormDataConsumer,
  FormTab,
  ImageField,
  ImageInput,
  ListButton,
  NumberInput,
  ReferenceArrayInput,
  ReferenceField,
  ReferenceInput,
  required,
  SelectInput,
  TabbedForm,
  TextField,
  TextInput,
  TopToolbar,
  UrlField,
} from "react-admin";
import { Grid, Paper, Typography } from "@material-ui/core";
import UniverseTitle from "../UniverseTitle";
import { rowStyles, useStyles } from "../MediaListStyle";
import RichTextInput from "ra-input-rich-text";
import { PublicationStatus, UniverseClass } from "../Universe.enum";
import ConditionalImageField from "../ConditionalImageField";
import TagReferenceInput from "../TagReferenceInput";
import ClearFieldButton from "../../Buttons/ClearFieldButton";
import resetCookies from "../../CfCookies";

import Config from "../../../Config";
import { useState } from "react";
import ChunkedUploady from "@rpldy/chunked-uploady";
import UploadButton from "@rpldy/upload-button";
import { useItemProgressListener } from "@rpldy/uploady";
import VideoTeaserField from "../VideoFieldTeaser";
import VideoMiniTeaserField from "../VideoFieldMiniTeaser";

const apiUrl = Config.API_URL;

const UniverseEditActions = ({ basePath, data, resource }: any) => (
  <TopToolbar>
    <ListButton basePath={basePath} />
    {/* <ShowButton basePath={basePath} record={data} /> */}
    {/* <RefreshButton color="primary" onClick={customAction} /> */}
  </TopToolbar>
);

const validateRequired = [required()];

const LogProgress = ({ callback }: { callback: Function }) => {
  useItemProgressListener((item) => {
    // setTimeout(() => {throw new Error("ERROR")}, 5000)
    // console.log(`>>>>> (hook) File ${item.file.name} completed: ${item.completed}`);
    if (+item.completed >= 100) {
      callback(
        "Votre vidéo a été envoyée, elle est en traitement coté serveur"
      );
    } else {
      callback(
        item.file.name + ":  " + item.completed.toFixed(2) + " % completed"
      );
    }
  });

  return null;
};

const UniverseEdit = (props: EditProps) => {
  // const [uploadMsg, setUploadMsg] = useState("");
  const [uploadTeaserMsg, setUploadTeaserMsg] = useState("");
  const classes = rowStyles();
  const srtFieldClass = useStyles();

  resetCookies();
  return (
    <Edit
      title={<UniverseTitle />}
      mutationMode="optimistic"
      actions={<UniverseEditActions />}
      // undoable={false}
      {...props}
    >
      <TabbedForm>
        <FormTab label="Summary">
          <TextInput label="Titre" source="title" validate={validateRequired} />
          <RichTextInput
            label="description"
            source="description"
            // theme= 'snow'
            toolbar={[
              ["bold", "italic", "underline", "strike"], // toggled buttons
              ["blockquote", "link"],

              // [{ header: 1 }, { header: 2 }], // custom button values
              [{ list: "ordered" }, { list: "bullet" }],
              [{ script: "sub" }, { script: "super" }], // superscript/subscript
              [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
              [{ direction: "rtl" }], // text direction

              [{ size: ["small", false, "large", "huge"] }], // custom dropdown
              // [{ header: [1, 2, 3, 4, 5, 6, false] }],

              [{ color: [] }, { background: [] }], // dropdown with defaults from theme
              [{ font: [] }],
              [{ align: [] }],

              ["clean"],
            ]}
            validate={validateRequired}
          />
          <NumberInput label="N° épisode" source="order" />
          <FormDataConsumer>
            {({ formData }) =>
              formData.class === UniverseClass.FIDELITY_REWARD && (
                <NumberInput label="Point fidélité" source="fidelityScore" />
              )
            }
          </FormDataConsumer>
          <SelectInput
            label="class"
            source="class"
            choices={[
              { id: UniverseClass.EPISODE, name: "Episode" },
              { id: UniverseClass.TEAM_MODEL, name: "Team Model" },
              { id: UniverseClass.FIDELITY_REWARD, name: "Vidéo privée" },
            ]}
            validate={validateRequired}
          />
          <SelectInput
            label="Status"
            source="publicationStatus"
            choices={[
              { id: PublicationStatus.DRAFT, name: "Brouillon" },
              { id: PublicationStatus.PUBLISHED, name: "Publié" },
            ]}
            validate={validateRequired}
          />
          <TextInput label="slug" source="slug" validate={validateRequired} />
        </FormTab>
        <FormTab label="Banner">
          <Grid container spacing={2} className={classes.widthAuto}>
            <Grid item xs={6}>
              <Paper elevation={10}>
                <Typography variant="h5">Banner.</Typography>
              </Paper>
              <Grid container spacing={2} className={classes.widthAuto}>
                <Grid item xs={6}>
                  <ConditionalImageField
                    source="banner"
                    title="current banner"
                  />
                </Grid>
                <Grid item xs={6}>
                  <ImageInput
                    source="banner_update"
                    label="Update Banner"
                    accept="image/*"
                    className={classes.displayFlexColumn}
                  >
                    <ImageField source="src" title="current banner" />
                  </ImageInput>
                  <ClearFieldButton
                    source="banner"
                    target="banner_update"
                    label="Clear"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Paper elevation={10}>
                <Typography variant="h5">Home Banner.</Typography>
              </Paper>
              <Grid container spacing={2} className={classes.widthAuto}>
                <Grid item xs={6}>
                  <ConditionalImageField
                    source="home_banner"
                    title="current home_banner"
                  />
                </Grid>
                <Grid item xs={6}>
                  <ImageInput
                    source="home_banner_update"
                    label="Update Home Banner"
                    accept="image/*"
                    className={classes.displayFlex}
                  >
                    <ImageField source="src" title="Home Banner" />
                  </ImageInput>
                  <ClearFieldButton
                    source="home_banner"
                    target="home_banner_update"
                    label="Clear"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Paper elevation={10}>
                <Typography variant="h5">Mini Teaser.</Typography>
              </Paper>
              <Grid container spacing={2} className={classes.widthAuto}>
                <Grid item xs={6}>
                  <ChunkedUploady
                    destination={{
                      url: `${apiUrl}/universe/${props.id}/upload-chunk-mini-teaser`,
                      headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                          "access_token"
                        )}`,
                      },
                    }}
                    retries={5}
                    // sendDataOnCreate
                    // chunkSize={1024}
                  >
                    <LogProgress callback={setUploadTeaserMsg} />
                    <UploadButton />
                  </ChunkedUploady>
                  <p>{uploadTeaserMsg}</p>
                  <VideoMiniTeaserField
                    source="mini_teaser"
                    title="desc"
                    label="video teaser"
                    // key={`teaser-video`+Math.random()}
                  />
                  {/* </Grid>
                <Grid item xs={6}> */}
                  <FileInput
                    source="mini_teaser_update"
                    label="Teaser video"
                    accept="video/*"
                    className={classes.hidden}
                  >
                    <FileField source="src" title="teaser video" />
                  </FileInput>
                  <ClearFieldButton
                    source="mini_teaser"
                    target="mini_teaser_update"
                    label="Clear"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}></Grid>
            <Grid item xs={6}>
              <Paper elevation={10}>
                <Typography variant="h5">Teaser video haut de page pour la home.</Typography>
              </Paper>
              <Grid container spacing={2} className={classes.widthAuto}>
                <Grid item xs={6}>
                  <ChunkedUploady
                    destination={{
                      url: `${apiUrl}/universe/${props.id}/uploadChunkTeaserVideo`,
                      headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                          "access_token"
                        )}`,
                      },
                    }}
                    retries={5}
                    // sendDataOnCreate
                    // chunkSize={1024}
                  >
                    <LogProgress callback={setUploadTeaserMsg} />
                    <UploadButton />
                  </ChunkedUploady>
                  <p>{uploadTeaserMsg}</p>
                  <VideoTeaserField
                    source="teaser_video"
                    title="desc"
                    label="video teaser"
                    // key={`teaser-video`+Math.random()}
                  />
                  {/* </Grid>
                <Grid item xs={6}> */}
                  <FileInput
                    source="teaser_video_update"
                    label="Teaser video"
                    accept="video/*"
                    className={classes.hidden}
                  >
                    <FileField source="src" title="teaser video" />
                  </FileInput>
                  <ClearFieldButton
                    source="teaser_video"
                    target="teaser_video_update"
                    label="Clear"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Paper elevation={10}>
                <Typography variant="h5">Teaser subtitles.</Typography>
              </Paper>
              <Grid container spacing={2} className={classes.widthAuto}>
                <Grid item xs={6} style={{ overflow: "hidden" }}>
                  <UrlField
                    label="Teaser Subtitles"
                    source="teaser_srt"
                    className={srtFieldClass.srtUrl}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FileInput
                    source="teaser_srt_update"
                    label="Tease subtitles"
                    accept=".srt"
                  >
                    <FileField source="src" title="teaser subtitles" />
                  </FileInput>
                  <ClearFieldButton
                    source="teaser_srt"
                    target="teaser_srt_update"
                    label="Clear"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </FormTab>

        <FormTab label="Contenu">
          <Grid container spacing={2} className={classes.widthAuto}>
            <Grid item xs={12}>
              <Grid container spacing={2} className={classes.widthAuto}>
                <Grid item xs={6}>
                  <Paper elevation={10}>
                    <Typography variant="h5">teaser public.</Typography>
                  </Paper>
                  <Typography variant="button">
                    Modifier teaser public.
                  </Typography>
                  <ReferenceField
                    label="Teaser public"
                    source="teaserId"
                    reference="Video"
                    link="edit"
                  >
                    {/* <TextField source="media.title" /> */}
                    <TextField source="media.slug" />
                  </ReferenceField>
                  <ReferenceInput
                    label="Sélectionner teaser public"
                    source="teaserId"
                    reference="Video"
                    allowEmpty
                    fullWidth
                    perPage={10000}
                  >
                    {/* <AutocompleteInput optionText="media.title" /> */}
                    <AutocompleteInput optionText="media.slug" />
                    {/* <SelectInput optionText="media.title" /> */}
                  </ReferenceInput>
                </Grid>
                <Grid item xs={6}>
                  <Paper elevation={10}>
                    <Typography variant="h5">Vidéo complète.</Typography>
                  </Paper>
                  <Typography variant="button">
                    Modifier Vidéo complète.
                  </Typography>
                  <ReferenceField
                    label="Vidéo complète"
                    source="episodeId"
                    reference="Video"
                    link="edit"
                  >
                    {/* <TextField source="media.title" /> */}
                    <TextField source="media.slug" />
                  </ReferenceField>

                  <ReferenceInput
                    label="Sélectionner Vidéo complète"
                    source="episodeId"
                    reference="Video"
                    allowEmpty
                    fullWidth
                    perPage={10000}
                  >
                    {/* <AutocompleteInput optionText="media.title" /> */}
                    <AutocompleteInput optionText="media.slug" />
                    {/* <SelectInput optionText="media.title" /> */}
                  </ReferenceInput>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2} className={classes.widthAuto}>
                <Grid item xs={6}>
                  <Paper elevation={10}>
                    <Typography variant="h5">Gallerie.</Typography>
                  </Paper>
                  <Typography variant="button">
                    Modifier Vidéo complète.
                  </Typography>
                  <ReferenceField
                    label="Gallerie"
                    source="galleryId"
                    reference="Gallery"
                    link="edit"
                  >
                    {/* <TextField source="media.title" /> */}
                    <TextField source="media.slug" />
                  </ReferenceField>
                  <ReferenceInput
                    label="Sélectionner une Gallerie"
                    source="galleryId"
                    reference="Gallery"
                    allowEmpty
                    fullWidth
                    perPage={10000}
                  >
                    {/* <AutocompleteInput optionText="media.title" /> */}
                    <AutocompleteInput optionText="media.slug" />
                    {/* <SelectInput optionText="media.title" /> */}
                  </ReferenceInput>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </FormTab>

        <FormTab label="SEO">
          <TextInput
            label="Titre SEO"
            source="titleSeo"
            validate={validateRequired}
          />
          <TextInput
            label="description SEO"
            source="descriptionSeo"
            validate={validateRequired}
          />
          <TextInput label="keywords SEO" source="media.keywordsSeo" />
        </FormTab>
        <FormTab label="Tags">
          <TagReferenceInput reference="Tag" source="tagIds" label="tags" />
        </FormTab>
        <FormTab label="Web zones Universes">
          <ReferenceArrayInput
            source="webzoneIds"
            label="Web zones Universe"
            reference="WebzoneUniverse"
          >
            <AutocompleteArrayInput></AutocompleteArrayInput>
            {/* <SelectArrayInput optionText="name" /> */}
          </ReferenceArrayInput>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export default UniverseEdit;
