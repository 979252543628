import {
  List,
  Datagrid,
  TextField,
  DateInput,
  DateField,
  Edit,
  BooleanField,
  BooleanInput,
  TextInput,
  EditProps,
  ListProps,
  BulkDeleteButton,
  TopToolbar,
  ListButton,
  ArrayField,
  FormTab,
  TabbedForm,
  EmailField,
  NumberField,
  SelectInput,
  FunctionField,
  useRecordContext,
} from "react-admin";
import { useMediaQuery, Grid } from "@material-ui/core";
import { rowStyles } from "./Utils/Media/MediaListStyle";
import UnsubscribeButton from "./Utils/Buttons/UnsubscribeButton";
import { UserStatus } from "./Utils/User.enum";
import AnonymizeButton from "./Utils/Buttons/AnonymizeButton";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";

const userStatus = [
  { id: UserStatus.MEMBER, name: "Membres non abonnés" },
  { id: UserStatus.MEMBER_PREMIUM, name: "Membres premium" },
];
const userFilters = [
  <TextInput label="Search" source="q" alwaysOn />,
  // <ReferenceInput source="id" label="pseudo" reference="User" allowEmpty>
  //   <SelectInput optionText="login" />
  // </ReferenceInput>,
  // <ReferenceInput source="id" label="email" reference="User" allowEmpty>
  //   <SelectInput optionText="email" />
  // </ReferenceInput>,
  <SelectInput label="Type" source="status" choices={userStatus} />,
];

export const UserList = (props: ListProps) => {
  const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down("sm"));

  return (
    <List {...props} bulkActionButtons={false} filters={userFilters}>
      {isSmall ? (
        // <SimpleList
        //   primaryText={(record) => `${record.firstName} ${record.lastName}`}
        //   secondaryText={(record) => `${record.email}`}
        //   tertiaryText={(record) => `${record.status}`}
        // />
        <Datagrid rowClick="edit">
          <FunctionField
            label="Details"
            render={(record: any) => (
              <>
                <EmailField record={record} source="email" />
                <br></br>
                <TextField record={record} source="status" />
              </>
            )}
          />
          <NumberField label="Pallier Bonus" source="fidelityScore" />
          <AnonymizeButton />
          <UnsubscribeButton />
        </Datagrid>
      ) : (
        <Datagrid rowClick="edit">
          <TextField label="Firstname" source="firstName" />
          <TextField label="Lastname" source="lastName" />
          <TextField label="Pseudo" source="login" />
          <EmailField label="Email" source="email" />
          <TextField label="Status" source="status" />
          <NumberField label="Pallier Bonus" source="fidelityScore" />
          <DateField showTime={true} source="createdAt" />
          <DateField showTime={true} source="updatedAt" />
          <DateField
            showTime={false}
            label="Age Verification validity"
            source="ageVerificationValidUntil"
          />
          <AnonymizeButton />
          <UnsubscribeButton />
          <BulkDeleteButton />
        </Datagrid>
      )}
    </List>
  );
};

const AgeVerificationField = () => {
  const record = useRecordContext();
  if (!record) return null;

  return record.ageVerificationValidUntil ? (
    <DateField showTime={true} source="ageVerificationValidUntil" />
  ) : (
    <span>Non validé</span>
  );
};
const IsAdminField = () => {
  const record = useRecordContext();
  if (!record) return null;

  return record.isAdmin ? (
    <CheckIcon style={{ color: "green" }} />
  ) : (
    <CloseIcon style={{ color: "red" }} />
  );
};

const UserEditActions = ({ basePath, record, resource }: any) => (
  <TopToolbar>
    <ListButton basePath={basePath} />
  </TopToolbar>
);
export const UserEdit = (props: EditProps) => {
  rowStyles();
  return (
    <Edit
      title="OuiOui"
      mutationMode="optimistic"
      actions={<UserEditActions />}
      {...props}
    >
      <TabbedForm>
        <FormTab label="Profile">
          <Grid container spacing={3} style={{ width: "100%" }}>
            <Grid item lg md={3} sm={4}>
              <TextInput label="Prénom" source="firstName" />
            </Grid>
            <Grid item lg md={3} sm={4}>
              <TextInput label="Nom" source="lastName" />
            </Grid>
            <Grid item lg md={3} sm={4}>
              <TextInput label="Email" source="email" type="email" />
            </Grid>
            <Grid item lg md={3} sm={4}>
              <div>Date de création du compte : </div>
              <DateField showTime={true} source="createdAt" />
            </Grid>
            <Grid item lg md={3} sm={4}>
              <div>pseudo</div>
              <TextField label="Login" source="login" />
            </Grid>
            <Grid item lg md={3} sm={4}>
              <BooleanInput label="Compte actif" source="isActive" />
            </Grid>
            <Grid item lg md={3} sm={4}>
              <div>est Admin</div>
              <IsAdminField />
              </Grid>
          </Grid>
          <Grid container spacing={1} style={{ width: "100%" }}>
            <Grid item lg md={3} sm={4}>
              <DateInput
                showTime={false}
                label="Date anniversaire"
                source="birthday"
              />
            </Grid>
            <Grid item lg md={2} sm={4}>
              <div>Anonymiser</div>
              <AnonymizeButton />
            </Grid>
            <Grid item lg md={2} sm={4}>
              <UnsubscribeButton />
            </Grid>
            <Grid item lg md={3} sm={4}>
              <div>Validité Age verification : </div>
              <AgeVerificationField />
              
            </Grid>
          </Grid>
        </FormTab>
        <FormTab label="Subscription">
          <TextField
            label="Titre"
            source="subscriptionUser.subscription.title"
          />
          <TextField
            label="Durée"
            source="subscriptionUser.subscription.period"
          />
          <TextField
            label="Prix"
            source="subscriptionUser.subscription.price"
          />
          <DateField
            showTime={true}
            source="subscriptionUser.subscription.createdAt"
          />
        </FormTab>
        <FormTab label="Transactions">
          <ArrayField source="subscriptionUser.transactions">
            <Datagrid>
              <TextField label="Titre" source="title" />
              <TextField label="Prix" source="amount" />
              <TextField label="Nombre de tentatives" source="numberAttempt" />
              <TextField label="Paiement pour le" source="forPaiementAt" />
              <DateField showTime={true} source="createdAt" />
              <DateField showTime={true} source="updatedAt" />
            </Datagrid>
          </ArrayField>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};
