import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Config from "../../Config";
import { fetchUtils } from "react-admin";
import CardSurveyBarchartNew from "./cardSurveyBarchartNew";
import CardSurveyStats from "./cardSurveyStats";
import { SurveyStatus } from "../../Utils/Surveys/surveys.enum";

const apiUrl = Config.API_URL;
const httpClient = (url: string, options: any = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  const access_token = localStorage.getItem("access_token");
  options.headers.set("Authorization", `Bearer ${access_token}`);
  return fetchUtils.fetchJson(url, options);
};

type SurveyProps = {
  slug: string;
};

const AccordionSurvey = ({ slug }: SurveyProps) => {
  const [surveyTitle, setSurveyTitle] = useState("");
  const [surveyStatus, setSurveyStatus] = useState();
  const [surveyNbAnswered, setSurveyNbAnswered] = useState(0);
  const [surveyNbNotAnswered, setSurveyNbNotAnswered] = useState(0);
  const [refuseSurvey, setRefuseSurvey] = useState(0);
  const [answersStats, setAnswersStats] = useState([]);

  useEffect(() => {
    const url = `${apiUrl}/dashboard/survey_stats?slug=${slug}`;
    httpClient(url)
      .then((json) => {
        // console.log("DASHBOARD", json.body);
        const JsonBody = JSON.parse(json.body);
        setSurveyTitle(JsonBody.title);
        setSurveyStatus(JsonBody.surveyStatus);
        setSurveyNbAnswered(JsonBody.answered);
        setSurveyNbNotAnswered(JsonBody.notAnswered);
        setRefuseSurvey(JsonBody.refuseSurvey);
        setAnswersStats(JsonBody.answersStats);
        return json;
      })
      .catch((error) => {
        console.error("Error", error);
      });
  }, [slug]);
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel-content`}
        id={`panel-header`}
        style={{
          background:
            surveyStatus === SurveyStatus.ACTIVE ? "purple" : "inherit",
        }}
      >
        <Typography>
          {surveyTitle} - {surveyStatus}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid item xs={3}>
              <CardSurveyStats
                surveyNbAnswered={surveyNbAnswered}
                surveyNbNotAnswered={surveyNbNotAnswered}
                refuseSurvey={refuseSurvey}
              />
            </Grid>
          </Grid>

          {answersStats.map((stat: any, index) => (
            <Grid item xs={6} sm={6} key={index}>
              <CardSurveyBarchartNew statData={stat} />{" "}
            </Grid>
          ))}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default AccordionSurvey;
