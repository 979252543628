import RichTextInput from "ra-input-rich-text";
import {
  BooleanInput,
  BulkDeleteButton,
  Create,
  CreateProps,
  Datagrid,
  DateField,
  DateTimeInput,
  Edit,
  EditProps,
  FormDataConsumer,
  List,
  ListProps,
  required,
  SelectInput,
  SimpleForm,
  TextField,
} from "react-admin";
import { MessageTargetValue, UserStatus } from "./Utils/User.enum";
import { EmailWorkflow } from "./Utils/Message.enum";

const validateRequired = [required()];

export const GlobalMessageList = (props: ListProps) => (
  <List {...props} bulkActionButtons={false}>
    <Datagrid rowClick="edit">
      <TextField source="message" />
      <TextField source="target" />
      <TextField label="status" source="emailWorkflow" />
      <DateField showTime={true} source="sendEmailAt" />
      <DateField showTime={true} source="createdAt" />
      <DateField showTime={true} source="updatedAt" />
      <BulkDeleteButton />
    </Datagrid>
  </List>
);

export const GlobalMessageCreate = (props: CreateProps) => (
  <Create {...props}>
    <SimpleForm>
      <RichTextInput
        label="Message"
        source="message"
        validate={validateRequired}
      />
      <SelectInput
        label="Cible"
        source="target"
        allowEmpty="true"
        choices={[
          { id: MessageTargetValue.MEMBER, name: "Membre" },
          { id: MessageTargetValue.MEMBER_PREMIUM, name: "Membre premium" },
          {
            id: MessageTargetValue.FORMER_PREMIUM_MEMBER,
            name: "Ancien Membre premium",
          },
        ]}
      />
      <BooleanInput
        label="Envoyer un email"
        source="sendEmail"
        defaultValue={false}
      />

      <FormDataConsumer>
        {({ formData }) =>
          formData.sendEmail && (
            <>
              <DateTimeInput
                label="Envoyer à partir de"
                source="sendEmailAt"
                validate={formData.sendEmail && validateRequired}
                disabled={!formData.sendEmail}
              />
              <RichTextInput
                label="Texte de l'Email"
                source="emailMessage"
                toolbar={[
                  ["bold", "italic", "underline", "strike"], // toggled buttons
                  ["blockquote", "code-block", "link", "image"],

                  [{ header: 1 }, { header: 2 }], // custom button values
                  [{ list: "ordered" }, { list: "bullet" }],
                  [{ script: "sub" }, { script: "super" }], // superscript/subscript
                  [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
                  [{ direction: "rtl" }], // text direction

                  [{ size: ["small", false, "large", "huge"] }], // custom dropdown
                  [{ header: [1, 2, 3, 4, 5, 6, false] }],

                  [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                  [{ font: [] }],
                  [{ align: [] }],

                  ["clean"],
                ]}
                validate={formData.sendEmail && validateRequired}
                options={{
                  readOnly: !formData.sendEmail,
                }}
              />
            </>
          )
        }
      </FormDataConsumer>
    </SimpleForm>
  </Create>
);

export const GlobalMessageEdit = (props: EditProps) => {
  // const form = useForm();
  return (
    <Edit mutationMode="pessimistic" {...props}>
      <SimpleForm>
        <RichTextInput
          label="Message"
          source="message"
          validate={validateRequired}
        />
        <SelectInput
          label="Cible"
          source="target"
          allowEmpty="true"
          choices={[
            { id: MessageTargetValue.MEMBER, name: "Membre" },
            { id: MessageTargetValue.MEMBER_PREMIUM, name: "Membre premium" },
            {
              id: MessageTargetValue.FORMER_PREMIUM_MEMBER,
              name: "Ancien Membre premium",
            },
          ]}
        />
        <TextField label="status" source="emailWorkflow" />
        <FormDataConsumer>
          {({ formData }) =>
            (!formData.emailWorkflow ||
              formData.emailWorkflow === EmailWorkflow.TO_SEND ||
              formData.emailWorkflow === EmailWorkflow.ERROR) && (
              <>
                <BooleanInput
                  label="Envoyer un email"
                  source="sendEmail"
                  // defaultChecked={formData.sendEmailAt ? true : false}
                  defaultValue={
                    formData.emailWorkflow === EmailWorkflow.TO_SEND
                      ? true
                      : false
                  }
                  disabled={
                    formData.emailWorkflow === EmailWorkflow.SENT ||
                    formData.emailWorkflow === EmailWorkflow.SENDING
                  }
                />

                {formData.emailWorkflow !== EmailWorkflow.SENT &&
                  formData.emailWorkflow !== EmailWorkflow.SENDING &&
                  formData.sendEmail && (
                    <>
                      <DateTimeInput
                        label="Envoyer à partir de"
                        source="sendEmailAt"
                        validate={validateRequired}
                        disabled={
                          formData.emailWorkflow === EmailWorkflow.SENT ||
                          formData.emailWorkflow === EmailWorkflow.SENDING ||
                          !formData.sendEmail
                        }
                      />

                      <RichTextInput
                        label="Texte de l'Email"
                        source="emailMessage"
                        toolbar={[
                          ["bold", "italic", "underline", "strike"], // toggled buttons
                          ["blockquote", "code-block", "link", "image"],

                          [{ header: 1 }, { header: 2 }], // custom button values
                          [{ list: "ordered" }, { list: "bullet" }],
                          [{ script: "sub" }, { script: "super" }], // superscript/subscript
                          [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
                          [{ direction: "rtl" }], // text direction

                          [{ size: ["small", false, "large", "huge"] }], // custom dropdown
                          [{ header: [1, 2, 3, 4, 5, 6, false] }],

                          [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                          [{ font: [] }],
                          [{ align: [] }],

                          ["clean"],
                        ]}
                        validate={validateRequired}
                        options={{
                          readOnly:
                            formData.emailWorkflow === EmailWorkflow.SENT ||
                            formData.emailWorkflow === EmailWorkflow.SENDING ||
                            !formData.sendEmail,
                        }}
                      />
                    </>
                  )}
              </>
            )
          }
        </FormDataConsumer>
      </SimpleForm>
    </Edit>
  );
};
