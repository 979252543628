import { fetchUtils, Identifier } from "react-admin";
import { stringify } from "querystring";
import Config from "./Config";
import MediaDataProvider from "./Utils/Media/Media.service";
import UniverseDataProvider from "./Utils/Media/Universe.service";
import { memoize } from "lodash";
import NewsDataProvider from "./Utils/News/News.service";
import { EmailWorkflow } from "./Utils/Message.enum";

const apiUrl = Config.API_URL;
const MediaTypes = ["Gallery", "Video"];

// const httpClient = fetchUtils.fetchJson;
const httpClient = (url: string, options: any = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  const access_token = localStorage.getItem("access_token");
  options.headers.set("Authorization", `Bearer ${access_token}`);
  return fetchUtils.fetchJson(url, options);
};

const dataProvider = {
  getList: async (
    resource: any,
    params: any
  ): Promise<{ data: any; total: any }> => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify(params.filter),
    };
    // console.log("getList query"  , query);
    // const url = `${apiUrl}/${resource}?${stringify(query)}`;
    let url = `${apiUrl}/${resource}?page=${page}&perPage=${perPage}`;
    if (resource === "Messages") {
      url = `${apiUrl}/${resource}/lasts?page=${page}&perPage=${perPage}`;
    }
    if (query.filter && query.filter !== "{}") {
      url = `${url}&filter=${query.filter}`;
    }
    if (query.sort) {
      url = `${url}&sort=[["${field}","${order}"]]`;
    }
    const { json } = await httpClient(url);
    if (MediaTypes.includes(resource) && json.rows.length <= 25) {
      json.rows = await MediaDataProvider.getMediasBanner(json.rows);
    } else if (resource === "Universe") {
      json.rows = await UniverseDataProvider.getUniversesBanner(json.rows);
    }
    // if (resource === "Video") {
    //   json.rows = await MediaDataProvider.getSeason(json.rows);
    // }
    if (resource === "Universe") {
      json.rows = await UniverseDataProvider.getSeason(json.rows);
    }
    // if (json.rows && json.rows.length === 0) {
    //     return ({
    //         data: [],
    //         total: 0})
    // }
    if (json.total === undefined) {
      json.total = json.length;
    }

    return {
      data: json.rows || json,
      total: json.total,
    };
  },

  getOne: async (resource: string, params: { id: Identifier }) => {
    let { json } = await httpClient(`${apiUrl}/${resource}/${params.id}`);

    if (MediaTypes.includes(resource)) {
      json = await MediaDataProvider.getMediaContent(resource, json);
    }
    if (resource === "Universe") {
      json = await UniverseDataProvider.getUniverseContent(resource, json);
    }
    if (resource === "News") {
      json = await NewsDataProvider.getNewsContent(resource, json);
    }
    if (resource === "Messages") {
      const userFilter = JSON.stringify({
        user_id: json.user_id,
      });
      const otherMessages = await httpClient(
        `${apiUrl}/${resource}?filter=${userFilter}&sort=[["createdAt","DESC"]]&page=1&perPage=1000`
      );
      json.otherMessages = otherMessages.json.rows;
    }
    return { data: json };
  },

  getMany: async (
    resource: string,
    params: { ids: Identifier[] }
  ): Promise<{ data: any }> => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;
    const { json } = await httpClient(url);
    return { data: json.rows || json };
  },

  getManyReference: (
    resource: string,
    params: {
      pagination: { page: number; perPage: number };
      sort: { field: any; order: any };
      filter: any;
      target: any;
      id: any;
    }
  ) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id,
      }),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;

    return httpClient(url).then(({ headers, json }: any) => ({
      data: json,
      total: parseInt(headers.get("content-range").split("/").pop(), 10),
    }));
  },

  update: async (
    resource: string,
    params: { id: Identifier; data: any; previousData: any }
  ) => {
    try {
      // console.log("dataProvider UPDATE", resource, params.data);
      if (MediaTypes.includes(resource)) {
        await MediaDataProvider.updateMedia(resource, params);
      } else if (resource === "Universe") {
        await UniverseDataProvider.updateUniverse(resource, params);
      } else if (resource === "News") {
        await NewsDataProvider.updateNews(resource, params);
      } else if (resource === "GlobalMessage") {
        const data = { ...params.data };
        if (
          !params.data.sendEmail &&
          params.data.emailWorkflow === EmailWorkflow.TO_SEND
        ) {
          data.emailWorkflow = null;
        } else if (params.data.sendEmail && !params.data.emailWorkflow) {
          data.emailWorkflow = EmailWorkflow.TO_SEND;
        }
        const { json } = await httpClient(
          `${apiUrl}/${resource}/${params.id}`,
          {
            method: "PATCH",
            body: JSON.stringify(data),
          }
        );
        return {
          data: { ...params.data, id: json.id },
        };
      } else {
        let url = `${apiUrl}/${resource}/${params.id}`;
        await httpClient(url, {
          method: "PATCH",
          body: JSON.stringify(params.data),
        });
      }
      let { json } = await httpClient(`${apiUrl}/${resource}/${params.id}`);
      return { data: json };
    } catch (error) {
      console.log(error);
      throw error;
    }
  },

  updateMany: async (
    resource: string,
    params: { ids: Identifier[]; data: any }
  ) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    const { json } = await httpClient(
      `${apiUrl}/${resource}?${stringify(query)}`,
      {
        method: "PUT",
        body: JSON.stringify(params.data),
      }
    );
    return { data: json };
  },

  create: async (resource: string, params: { data: any }) => {
    // console.log("resource", resource);
    // console.log("params", params);
    try {
      if (MediaTypes.includes(resource)) {
        // console.log("params", params)
        // params.data.media.type = MediaType.GALLERY;
        const json = await MediaDataProvider.createMedia(resource, params);
        return {
          data: { ...params.data.media, id: json.id },
        };
      } else if (resource === "Universe") {
        console.log("Creating Universe");
        console.log(params);
        const json = await UniverseDataProvider.createUniverse(
          resource,
          params
        );
        return {
          data: { ...params.data, id: json.id },
        };
      } else if (resource === "GlobalMessage") {
        const data = { ...params.data };
        if (params.data.sendEmail) {
          data.emailWorkflow = EmailWorkflow.TO_SEND;
        }
        const { json } = await httpClient(`${apiUrl}/${resource}`, {
          method: "POST",
          body: JSON.stringify(data),
        });
        return {
          data: { ...params.data, id: json.id },
        };
      } else {
        const { json } = await httpClient(`${apiUrl}/${resource}`, {
          method: "POST",
          body: JSON.stringify(params.data),
        });
        return {
          data: { ...params.data, id: json.id },
        };
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  },

  delete: async (resource: string, params: { id: Identifier }) => {
    try {
      const { json } = await httpClient(`${apiUrl}/${resource}/${params.id}`, {
        method: "DELETE",
      });
      return { data: json };
    } catch (error) {
      throw error;
    }
  },

  deleteMany: async (resource: string, params: { ids: Identifier[] }) => {
    try {
      let response: any[] = [];
      for (const id of params.ids) {
        await httpClient(`${apiUrl}/${resource}/${id}`, {
          method: "DELETE",
        }).then(({ json }) => {
          response.push(json);
        });
      }
      return { data: response };

      // const query = {
      //     filter: JSON.stringify({ id: params.ids}),
      // };
      // return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
      //     method: 'DELETE',
      // }).then(({ json }) => ({ data: json }));
    } catch (error) {
      throw error;
    }
  },

  checkTag: async (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (e.currentTarget.value.length > 2) {
      let url = `${apiUrl}/tag/find_similar?label=${e.currentTarget.value}`;
      const { json } = await httpClient(url);
      console.log("checkTag", json);
    }
  },

  unsubscribeUser: async (params: { id: Identifier; date: Date }) => {
    console.log("unsubscribeUser", params);
    let url = `${apiUrl}/users/${params.id}/forceUnsubscribe`;
    console.log('url', url);
    let { json } = await httpClient(url, {
      method: "PATCH",
      body: JSON.stringify({ date: params.date}),
    });
    return { data: json };
  },
  anonymizeUser: async (params: { id: Identifier }) => {
    console.log("anonymizeUser", params);
    let url = `${apiUrl}/users/${params.id}/anonymize`;
    console.log('url', url);
    let { json } = await httpClient(url, {
      method: "PATCH",
    });
    return { data: json };
  },


  getSignedCookies: async () => {
    try {
      let url = `${apiUrl}/media/adminSignedCookies`;
      const { json } = await httpClient(url);
      return json;
    } catch (_) {
      // throw error;
    }
  },
  memoizeGetSignedCookies: () => {
    return {} as any;
  },
};

dataProvider.memoizeGetSignedCookies = memoize(dataProvider.getSignedCookies);

export default dataProvider;
