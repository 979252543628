import {
  ArrayField,
  BulkDeleteButton,
  Datagrid,
  DateField,
  Edit,
  EditProps,
  EmailField,
  FilterList,
  FilterListItem,
  List,
  ListProps,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
} from "react-admin";
import { UserMsgStatus } from "./Utils/Message.enum";
import {
  Card as MuiCard,
  CardContent,
  withStyles,
  Typography,
} from "@material-ui/core";
import BookmarksTwoToneIcon from "@material-ui/icons/BookmarksTwoTone";
const msgStatus = [
  { id: UserMsgStatus.PENDING, name: "En attente de réponse" },
  { id: UserMsgStatus.REMINDED, name: "Rappel de réponse" },
  { id: UserMsgStatus.ANSWERED, name: "Répondu" },
  { id: UserMsgStatus.ANSWER_READ, name: "Réponse lue" },
];

const postFilters = [
  <TextInput label="Search" source="q" alwaysOn />,
  <SelectInput label="Status" source="status" choices={msgStatus} />,
  <ReferenceInput source="user_id" reference="User">
    <SelectInput optionText="email" />
  </ReferenceInput>,
];

const Card = withStyles((theme) => ({
  root: {
    [theme.breakpoints.up("sm")]: {
      order: -1, // display on the left rather than on the right of the list
      width: "15em",
      marginRight: "1em",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}))(MuiCard);

// const Status1Filter = () => (
//   <FilterList label="Status" icon={<BookmarksTwoToneIcon />}>
//     <FilterListItem
//       label="En attente de réponse"
//       value={{
//         status: UserMsgStatus.PENDING,
//       }}
//     />
//     <FilterListItem
//       label="Rappel de réponse"
//       value={{
//         status: UserMsgStatus.REMINDED,
//       }}
//     />
//     <FilterListItem
//       label="Répondu"
//       value={{
//         status: UserMsgStatus.ANSWERED,
//       }}
//     />
//     <FilterListItem
//       label="Réponse lue"
//       value={{
//         status: UserMsgStatus.ANSWER_READ,
//       }}
//     />
//   </FilterList>
// );
const StatusFilter = () => (
  <FilterList label="Status" icon={<BookmarksTwoToneIcon />}>
    {msgStatus.map((segment) => (
      <FilterListItem
        label={segment.name}
        key={segment.id}
        value={{ status: segment.id }}
      />
    ))}
  </FilterList>
);
const FilterSidebar = () => (
  <Card style={{ width: "15vw" }}>
    <CardContent>
      {/* <FilterLiveSearch source="user" /> */}
      <StatusFilter />
    </CardContent>
  </Card>
);
export const MessageList = (props: ListProps) => (
  <List
    {...props}
    bulkActionButtons={false}
    filters={postFilters}
    // filter={{ status: UserMsgStatus.ANSWER_READ }}
    aside={<FilterSidebar />}
  >
    <Datagrid rowClick="edit">
      {/* <TextField source="id" />
      <TextField source="user_id" /> */}
      <ReferenceField label="User" source="user_id" reference="User">
        <TextField source="login" />
      </ReferenceField>
      <ReferenceField label="Email" source="user_id" reference="User">
        <EmailField source="email" />
      </ReferenceField>
      <TextField source="message" />
      <TextField source="status" />
      <TextField source="answer" />
      <DateField showTime={true} source="createdAt" />
      <DateField showTime={true} source="updatedAt" />
      <BulkDeleteButton />
    </Datagrid>
  </List>
);

// export const MessageCreate = (props: CreateProps) => (
//     <Create {...props}>
//         <SimpleForm>
//             <TextInput source="label" />
//         </SimpleForm>
//     </Create>
// );
const EditAside = () => {
  return (
    <div
      style={{
        width: "60%",
        margin: "1em",
        maxHeight: "80vh",
        overflow: "auto",
      }}
    >
      <Typography variant="h6">Message History</Typography>
      <ArrayField source="otherMessages" fieldKey="id">
        <Datagrid>
          <div>
            <div
              style={{
                textAlign: "left",
                // width: "fit-content",
                marginRight: "auto",
                display: "flex",
                flexDirection: "column",
                alignContent: "flex-start",
                alignItems: "flex-start",
              }}
            >
              <DateField source="createdAt" showTime textAlign="left" />
              <TextField
                source="answer"
                textAlign="left"
                style={{
                  maxWidth: "50%",
                  backgroundColor: "#646060",
                  padding: "0.5em",
                  borderRadius: "0.5em",
                }}
              />
              {/* <ChipField source="message" textAlign="left" /> */}
            </div>
            <div
              style={{
                // textAlign: "right",
                // width: "fit-content",
                marginLeft: "auto",
                display: "flex",
                flexDirection: "column",
                alignContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <DateField source="updatedAt" showTime textAlign="right" />
              <TextField
                source="message"
                textAlign="left"
                style={{
                  maxWidth: "50%",
                  backgroundColor: "#646060",
                  padding: "0.5em",
                  borderRadius: "0.5em",
                }}
              />
              {/* <ChipField source="answer" textAlign="right" /> */}
            </div>
          </div>
        </Datagrid>
      </ArrayField>
    </div>
  );
};

export const MessageEdit = (props: EditProps) => {
  return (
    <Edit mutationMode="pessimistic" aside={<EditAside />} {...props}>
      <SimpleForm>
        {/* <TextField source="user_id" /> */}
        <ReferenceField label="User" source="user_id" reference="User">
          <TextField source="login" />
        </ReferenceField>
        <ReferenceField label="Email" source="user_id" reference="User">
          <EmailField source="email" />
        </ReferenceField>

        <TextField source="message" />
        <TextField source="status" />
        <TextInput source="answer" fullWidth multiline />
      </SimpleForm>
    </Edit>
  );
};
