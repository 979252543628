import RichTextInput from "ra-input-rich-text";
import {
  AutocompleteArrayInput,
  AutocompleteInput,
  Create,
  CreateProps,
  FileField,
  FileInput,
  FormDataConsumer,
  FormTab,
  ImageField,
  ImageInput,
  NumberInput,
  ReferenceArrayInput,
  ReferenceField,
  ReferenceInput,
  required,
  SelectInput,
  TabbedForm,
  TextField,
  TextInput,
} from "react-admin";
import { PublicationStatus, UniverseClass } from "../Universe.enum";
import UniverseTitle from "../UniverseTitle";
import { Grid, Paper, Typography } from "@material-ui/core";
import { rowStyles, videoMsgStyle } from "../MediaListStyle";
import TagReferenceInput from "../TagReferenceInput";

const validateRequired = [required()];

const UniverseCreate = (props: CreateProps) => {
  const classes = rowStyles();
  const videoMsgClasse = videoMsgStyle();

  // const theResource = useResourceContext();
  // console.log(theResource);
  // const checkTag = async (
  //   e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  // ) => {
  //   const res = await dataProvider.checkTag(e);
  //   console.log(props);
  //   return res;
  // };
  return (
    <Create title={<UniverseTitle />} {...props}>
      <TabbedForm>
        <FormTab label="Summary">
          <TextInput label="Titre" source="title" validate={validateRequired} />
          <RichTextInput
            label="description"
            source="description"
            toolbar={[
              ["bold", "italic", "underline", "strike"], // toggled buttons
              ["blockquote", "link"],

              // [{ header: 1 }, { header: 2 }], // custom button values
              [{ list: "ordered" }, { list: "bullet" }],
              [{ script: "sub" }, { script: "super" }], // superscript/subscript
              [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
              [{ direction: "rtl" }], // text direction

              [{ size: ["small", false, "large", "huge"] }], // custom dropdown
              // [{ header: [1, 2, 3, 4, 5, 6, false] }],

              [{ color: [] }, { background: [] }], // dropdown with defaults from theme
              [{ font: [] }],
              [{ align: [] }],

              ["clean"],
            ]}
            validate={validateRequired}
          />
          <NumberInput label="N° épisode" source="order" />
          <FormDataConsumer>
            {({ formData }) =>
              formData.class === UniverseClass.FIDELITY_REWARD && (
                <NumberInput label="Point fidélité" source="fidelityScore" />
              )
            }
          </FormDataConsumer>

          <SelectInput
            label="class"
            source="class"
            choices={[
              { id: UniverseClass.EPISODE, name: "Episode" },
              { id: UniverseClass.TEAM_MODEL, name: "Team Model" },
              { id: UniverseClass.FIDELITY_REWARD, name: "Vidéo privée" },
            ]}
            initialValue={UniverseClass.EPISODE}
            validate={validateRequired}
          />

          <SelectInput
            label="Status"
            source="publicationStatus"
            choices={[
              { id: PublicationStatus.DRAFT, name: "Brouillon" },
              { id: PublicationStatus.PUBLISHED, name: "Publié" },
            ]}
            initialValue={PublicationStatus.DRAFT}
            validate={validateRequired}
          />
          <TextInput label="slug" source="slug" validate={validateRequired} />
        </FormTab>
        <FormTab label="Banner">
          <Grid container spacing={2} className={classes.widthAuto}>
            <Grid item xs={6}>
              <ImageInput source="banner" label="Banner" accept="image/*">
                <ImageField source="src" title="current banner" />
              </ImageInput>
            </Grid>
            <Grid item xs={6}>
              <ImageInput
                source="home_banner"
                label="Home Banner"
                accept="image/*"
              >
                <ImageField source="src" title="Home Banner" />
              </ImageInput>
            </Grid>
            <Grid item xs={6}>
              <Paper elevation={10}>
                <Typography variant="h5">Mini Teaser video.</Typography>
              </Paper>
              <div className={videoMsgClasse.videoMsg}>
                Renseignez les informations concertant l'univers, puis
                enregistrer, vous pourrez ensuite télécharger votre vidéo teaser
                pour la home page
              </div>
            </Grid>
            <Grid item xs={6}></Grid>
            <Grid item xs={6}>
              <Paper elevation={10}>
                <Typography variant="h5">Teaser video pour la home.</Typography>
              </Paper>
              <div className={videoMsgClasse.videoMsg}>
                Renseignez les informations concertant l'univers, puis
                enregistrer, vous pourrez ensuite télécharger votre vidéo teaser
                pour la home page
              </div>
              {/* <FileInput
                source="teaser_video"
                label="Teaser video"
                accept="video/*"
              >
                <FileField source="src" title="teaser video" />
              </FileInput> */}
            </Grid>
            <Grid item xs={6}>
              <FileInput
                source="teaser_srt"
                label="Tease subtitles"
                accept=".srt"
              >
                <FileField source="src" title="teaser subtitles" />
              </FileInput>
            </Grid>
          </Grid>
        </FormTab>
        <FormTab label="Contenu">
          <Grid container spacing={2} className={classes.widthAuto}>
            <Grid item xs={12}>
              <Grid container spacing={2} className={classes.widthAuto}>
                <Grid item xs={6}>
                  <Paper elevation={10}>
                    <Typography variant="h5">teaser public.</Typography>
                  </Paper>
                  <Typography variant="button">
                    Modifier teaser public.
                  </Typography>
                  <ReferenceField
                    label="Teaser public"
                    source="teaserId"
                    reference="Video"
                    link="edit"
                  >
                    {/* <TextField source="media.title" /> */}
                    <TextField source="media.slug" />
                  </ReferenceField>
                  <ReferenceInput
                    label="Sélectionner teaser public"
                    source="teaserId"
                    reference="Video"
                    allowEmpty
                    fullWidth
                    perPage={10000}
                  >
                    {/* <AutocompleteInput optionText="media.title" /> */}
                    <AutocompleteInput optionText="media.slug" />
                    {/* <SelectInput optionText="media.title" /> */}
                  </ReferenceInput>
                </Grid>
                <Grid item xs={6}>
                  <Paper elevation={10}>
                    <Typography variant="h5">Vidéo complète.</Typography>
                  </Paper>
                  <Typography variant="button">
                    Modifier Vidéo complète.
                  </Typography>
                  <ReferenceField
                    label="Vidéo complète"
                    source="episodeId"
                    reference="Video"
                    link="edit"
                  >
                    {/* <TextField source="media.title" /> */}
                    <TextField source="media.slug" />
                  </ReferenceField>

                  <ReferenceInput
                    label="Sélectionner Vidéo complète"
                    source="episodeId"
                    reference="Video"
                    allowEmpty
                    fullWidth
                    perPage={10000}
                  >
                    {/* <AutocompleteInput optionText="media.title" /> */}
                    <AutocompleteInput optionText="media.slug" />
                    {/* <SelectInput optionText="media.title" /> */}
                  </ReferenceInput>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2} className={classes.widthAuto}>
                <Grid item xs={6}>
                  <Paper elevation={10}>
                    <Typography variant="h5">Gallerie.</Typography>
                  </Paper>
                  <Typography variant="button">
                    Modifier Vidéo complète.
                  </Typography>
                  <ReferenceField
                    label="Gallerie"
                    source="galleryId"
                    reference="Gallery"
                    link="edit"
                  >
                    {/* <TextField source="media.title" /> */}
                    <TextField source="media.slug" />
                  </ReferenceField>
                  <ReferenceInput
                    label="Sélectionner une Gallerie"
                    source="galleryId"
                    reference="Gallery"
                    allowEmpty
                    fullWidth
                    perPage={10000}
                  >
                    {/* <AutocompleteInput optionText="media.title" /> */}
                    <AutocompleteInput optionText="media.slug" />
                    {/* <SelectInput optionText="media.title" /> */}
                  </ReferenceInput>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </FormTab>
        {/* {props.resource === "Video" && (
          <FormTab label="Video">
            <div className={videoMsgClasse.videoMsg}>
              Renseignez les informations concertant la video, puis enregistrer,
              vous pourrez ensuite télécharger votre vidéo
            </div>
            <FileInput
              source="srt_input"
              label="Video subtitle File"
              accept=".srt"
            >
              <FileField source="src" title="srt" />
            </FileInput>
          </FormTab>
        )}
        {props.resource === "Gallery" && (
          <FormTab label="Gallery">
            <ImageInput
              source="pictures_input"
              label="images"
              multiple={true}
              accept="image/*"
            >
              <ImageField source="src" title="image" />
            </ImageInput>
          </FormTab>
        )} */}
        <FormTab label="SEO">
          <TextInput
            label="Titre SEO"
            source="titleSeo"
            validate={validateRequired}
          />
          <TextInput
            label="description SEO"
            source="descriptionSeo"
            validate={validateRequired}
          />
          <TextInput label="keywords SEO" source="keywordsSeo" />
        </FormTab>
        <FormTab label="Tags">
          {/* <ReferenceArrayInput source="universe.tags" label="tags" reference="Tag">
                 <SelectArrayInput optionText="label" /> 
          </ReferenceArrayInput> */}
          <TagReferenceInput reference="Tag" source="tagIds" label="tags" />
        </FormTab>
        <FormTab label="Web zones Universes">
          <ReferenceArrayInput
            source="webzoneUniverseIds"
            label="Web zones Universes"
            reference="WebzoneUniverse"
          >
            <AutocompleteArrayInput></AutocompleteArrayInput>
          </ReferenceArrayInput>
        </FormTab>
      </TabbedForm>
    </Create>
  );
};

export default UniverseCreate;
