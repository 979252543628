import { removeCookies, setCookies } from "cookies-next";
import { OptionsType } from "cookies-next/lib/types";
import Config from "../Config";
import dataProvider from "../dataProvider";

const resetCookies = async () => {
  const cookies = await dataProvider.memoizeGetSignedCookies();
  const cookieOptions = {
    // maxAge: 60 * 6 * 24 * 5,
    secure: true,
    // httpOnly: true,
    path: "/",
    sameSite: "lax",
    domain: Config.CF_DOMAIN  || '.littleangel84.fr',
  } as OptionsType;
  removeCookies("CloudFront-Key-Pair-Id", cookieOptions);
  removeCookies("CloudFront-Signature", cookieOptions);
  removeCookies("CloudFront-Policy", cookieOptions);

  setCookies(
    "CloudFront-Key-Pair-Id",
    cookies["CloudFront-Key-Pair-Id"],
    cookieOptions
  );
  setCookies("CloudFront-Policy", cookies["CloudFront-Policy"], cookieOptions);
  setCookies(
    "CloudFront-Signature",
    cookies["CloudFront-Signature"],
    cookieOptions
  );

  // console.log('CloudFront-Key-Pair-Id', getCookie('CloudFront-Key-Pair-Id'));
  // console.log('CloudFront-Policy', getCookie('CloudFront-Policy'));
  // console.log('CloudFront-Signature', getCookie('CloudFront-Signature'));
};

export default resetCookies;
