export enum MediaType {
  VIDEO = "video",
  // VIDEO_CHALLENGE = "video_challenge",
  GALLERY = "gallery",
}

export enum AccessRestriction {
  PUBLIC = "public",
  MEMBER_PREMIUM = "member_premium",
}

export enum PublicationStatus {
  DRAFT = "draft",
  PUBLISHED = "published",
}
