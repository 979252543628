import {
  BooleanInput,
  Edit,
  EditProps,
  FileField,
  FileInput,
  FormTab,
  ImageField,
  ImageInput,
  ListButton,
  required,
  SelectInput,
  TabbedForm,
  TextInput,
  TopToolbar,
  UrlField,
} from "react-admin";
import { Grid, Paper, Typography } from "@material-ui/core";
import MediaTitle from "../MediaTitle";
import { rowStyles } from "../MediaListStyle";
import RichTextInput from "ra-input-rich-text";
import { AccessRestriction, PublicationStatus } from "../Media.enum";
import ConditionalImageField from "../ConditionalImageField";
import TagReferenceInput from "../TagReferenceInput";
import VideoField from "../VideoField";
import ClearFieldButton from "../../Buttons/ClearFieldButton";
import VideoTeaserField from "../VideoFieldTeaser";
import GalleryForm from "../GalleryForm";
import ChunkedUploady from "@rpldy/chunked-uploady";
import UploadButton from "@rpldy/upload-button";
import { useItemProgressListener } from "@rpldy/uploady";
import { useState } from "react";
import Config from "../../../Config";
import { useStyles } from "../MediaListStyle";
import resetCookies from "../../CfCookies";

const apiUrl = Config.API_URL;

const MediaEditActions = ({ basePath, data, resource }: any) => (
  <TopToolbar>
    <ListButton basePath={basePath} />
    {/* <ShowButton basePath={basePath} record={data} /> */}
    {/* <RefreshButton color="primary" onClick={customAction} /> */}
  </TopToolbar>
);

const validateRequired = [required()];
const LogProgress = ({ callback }: { callback: Function }) => {
  useItemProgressListener((item) => {
    // setTimeout(() => {throw new Error("ERROR")}, 5000)
    // console.log(`>>>>> (hook) File ${item.file.name} completed: ${item.completed}`);
    if (+item.completed >= 100) {
      callback(
        "Votre vidéo a été envoyée, elle est en traitement coté serveur"
      );
    } else {
      callback(
        item.file.name + ":  " + item.completed.toFixed(2) + " % completed"
      );
    }
  });

  return null;
};

const MediaEdit = (props: EditProps) => {
  const [uploadMsg, setUploadMsg] = useState("");
  const [uploadTeaserMsg, setUploadTeaserMsg] = useState("");
  const classes = rowStyles();
  const srtFieldClass = useStyles();
  resetCookies();
  return (
    <Edit
      title={<MediaTitle />}
      mutationMode="optimistic"
      actions={<MediaEditActions />}
      // undoable={false}
      {...props}
    >
      <TabbedForm>
        <FormTab label="Summary">
          <TextInput
            label="Titre"
            source="media.title"
            validate={validateRequired}
          />
          <RichTextInput
            label="description"
            source="media.description"
            // theme= 'snow'
            toolbar={[
              ["bold", "italic", "underline", "strike"], // toggled buttons
              ["blockquote", "link"],

              // [{ header: 1 }, { header: 2 }], // custom button values
              [{ list: "ordered" }, { list: "bullet" }],
              [{ script: "sub" }, { script: "super" }], // superscript/subscript
              [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
              [{ direction: "rtl" }], // text direction

              [{ size: ["small", false, "large", "huge"] }], // custom dropdown
              // [{ header: [1, 2, 3, 4, 5, 6, false] }],

              [{ color: [] }, { background: [] }], // dropdown with defaults from theme
              [{ font: [] }],
              [{ align: [] }],

              ["clean"],
            ]}
            validate={validateRequired}
          />
          <SelectInput
            label="Access restriction"
            source="media.accessRestriction"
            choices={[
              { id: AccessRestriction.PUBLIC, name: "Public" },
              { id: AccessRestriction.MEMBER_PREMIUM, name: "Membre Premium" },
            ]}
            validate={validateRequired}
          />
          <SelectInput
            label="Status"
            source="media.publicationStatus"
            choices={[
              { id: PublicationStatus.DRAFT, name: "Brouillon" },
              { id: PublicationStatus.PUBLISHED, name: "Publié" },
            ]}
            validate={validateRequired}
          />
          <TextInput
            label="slug"
            source="media.slug"
            validate={validateRequired}
          />
        </FormTab>
        <FormTab label="Banner">
          <Grid container spacing={2} className={classes.widthAuto}>
            <Grid item xs={6}>
              <Paper elevation={10}>
                <Typography variant="h5">Banner.</Typography>
              </Paper>
              <Grid container spacing={2} className={classes.widthAuto}>
                <Grid item xs={6}>
                  <ConditionalImageField
                    source="banner"
                    title="current banner"
                  />
                </Grid>
                <Grid item xs={6}>
                  <ImageInput
                    source="banner_update"
                    label="Update Banner"
                    accept="image/*"
                    className={classes.displayFlexColumn}
                  >
                    <ImageField source="src" title="current banner" />
                  </ImageInput>
                  <ClearFieldButton
                    source="banner"
                    target="banner_update"
                    label="Clear"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Paper elevation={10}>
                <Typography variant="h5">Home Banner.</Typography>
              </Paper>
              <Grid container spacing={2} className={classes.widthAuto}>
                <Grid item xs={6}>
                  <ConditionalImageField
                    source="home_banner"
                    title="current home_banner"
                  />
                </Grid>
                <Grid item xs={6}>
                  <ImageInput
                    source="home_banner_update"
                    label="Update Home Banner"
                    accept="image/*"
                    className={classes.displayFlex}
                  >
                    <ImageField source="src" title="Home Banner" />
                  </ImageInput>
                  <ClearFieldButton
                    source="home_banner"
                    target="home_banner_update"
                    label="Clear"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </FormTab>
        {props.resource === "Video" && (
          <FormTab label="Video">
            <ChunkedUploady
              destination={{
                url: `${apiUrl}/video/${props.id}/uploadChunkVideo`,
                headers: {
                  Authorization: `Bearer ${localStorage.getItem(
                    "access_token"
                  )}`,
                },
              }}
              retries={5}
              // sendDataOnCreate
              // chunkSize={1024}
            >
              <LogProgress callback={setUploadMsg} />
              <UploadButton />
            </ChunkedUploady>
            <p>{uploadMsg}</p>
            <VideoField source="video_url" title="desc" label="video image" />
            <FileInput
              source="video_input"
              label="Video"
              accept=".mp4"
              className={classes.hidden}
            >
              <FileField source="src" title=" subtitles" />
            </FileInput>
            <ClearFieldButton
              source="video_url"
              target="video_input"
              label="Clear"
            />
            <UrlField
              label="Subtitle file"
              source="srt_url"
              className={srtFieldClass.srtUrl}
            />
            <FileInput
              source="srt_input"
              label="Video subtitle File"
              accept=".srt"
            >
              <FileField source="src" title=" subtitles" />
            </FileInput>
            <ClearFieldButton
              source="srt_url"
              target="srt_input"
              label="Clear"
            />
          </FormTab>
        )}
        {props.resource === "Gallery" && (
          <FormTab label="Gallery">
            <GalleryForm {...props} />
          </FormTab>
        )}
        <FormTab label="SEO">
          <TextInput
            label="Titre SEO"
            source="media.titleSeo"
            validate={validateRequired}
          />
          <TextInput
            label="description SEO"
            source="media.descriptionSeo"
            validate={validateRequired}
          />
          <TextInput label="keywords SEO" source="media.keywordsSeo" />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export default MediaEdit;
